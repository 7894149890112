<template>
  <v-container grid-list-xs class="pa-12">
    <h1>Testimonials</h1>
    <v-layout row class="ma-0" align-center justify-end>
      <v-btn @click="$router.push({ name: 'AddNewTestimonial' })" dark>
        <v-icon>mdi-plus</v-icon>
        Add New Testimonial
      </v-btn>
    </v-layout>
    <v-divider class="my-6"></v-divider>
    <v-layout row class="ma-0">
      <v-card width="100%">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="testimonials"
          :items-per-page="20"
          :loading="loading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-img
              height="64"
              width="64"
              :src="item.image"
              style="border-radius:50%; background-size: cover !important; object-fit: cover !important;"
            ></v-img>
            <!-- {{item}} -->
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <v-btn icon @click="deleteTestimonial(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export default {
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        { text: "Image", value: "image" },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Review (en)", value: "review_en" },
        { text: "Review (es)", value: "review_es" },
        { text: "", sortable: false, value: "id" },
      ],
      testimonials: [],
      imagePreview: false,
      previewImages: [],
    };
  },
  methods: {
    openImagePreview(photos) {
      this.imagePreview = true;
      this.previewImages = photos;
    },
    // resizedPhoto(photo) {
    //   return photo.replace(
    //     "https://res.cloudinary.com/advargo-sports/image/upload/",
    //     "https://res.cloudinary.com/advargo-sports/image/upload/w_50,h_50,c_fill/"
    //   );
    // },
    getTestimonials() {
      this.testimonials = [];
      this.loading = true;
      let db = firebase.firestore();
      db.collection("testimonials")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.testimonials.push({ id: doc.id, ...doc.data() });
            console.log(doc.data());
          });
          this.loading = false;
        });
    },
    deleteTestimonial(id) {
      this.loading = true;
      let db = firebase.firestore();
      db.collection("testimonials")
        .doc(id)
        .delete()
        .then(() => {
          this.loading = false;
          this.getTestimonials();
        });
    },
  },
  mounted() {
    this.getTestimonials();
  },
};
</script>

<style></style>
