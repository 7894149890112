<template>
  <v-container grid-list-xs class="pa-12">
    <v-layout row class="ma-0" align-center justify-start>
      <v-btn icon @click="$router.go(-1)" class="mr-4">
        <v-icon color="black">mdi-arrow-left</v-icon>
      </v-btn>
      <h1>Add New Job</h1>
    </v-layout>
    <v-card class="pa-4 my-6">
      <v-layout row wrap class="ma-0">
        <v-flex xs12 md6 class="px-2">
          <v-text-field
            name="jobTitleEn"
            label="Job Title English"
            id="jobTitleEn"
            :messages="slug"
            v-model="jobTitleEn"
            @keyup="makeSlug"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md6 class="px-2">
          <v-text-field
            name="jobTitleEs"
            label="Job Title Spanish"
            id="jobTitleEs"
            v-model="jobTitleEs"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md6 class="px-2">
          <v-text-field
            name="jobLocationEn"
            label="Job Location English"
            id="jobLocationEn"
            v-model="jobLocationEn"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md6 class="px-2">
          <v-text-field
            name="jobLocationEs"
            label="Job Location Spanish"
            id="jobLocationEs"
            v-model="jobLocationEs"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout row wrap class="ma-0">
        <v-flex xs12 md6 class="px-2">
          <h3 class="mb-2">Job Description English</h3>
          <wysiwyg v-model="jobDescriptionEn" />
        </v-flex>
        <v-flex xs12 md6 class="px-2">
          <h3 class="mb-2">Job Description Spanish</h3>
          <wysiwyg v-model="jobDescriptionEs" />
        </v-flex>
      </v-layout>
      <v-divider class="my-6"></v-divider>
      <v-progress-linear
        :indeterminate="true"
        v-if="loading"
      ></v-progress-linear>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark v-if="!uploading" @click="addJob">
          Add Job
        </v-btn>
        <v-btn dark v-else>
          {{ `Adding ${uploaded}` }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import slugify from "slugify";
// import Axios from "axios";

export default {
  data() {
    return {
      loading: false,
      slug: "",
      jobTitleEn: "",
      jobTitleEs: "",
      jobDescriptionEn: "",
      jobDescriptionEs: "",
      jobLocationEn: "",
      jobLocationEs: "",

      loopIndex: 0,
      uploading: false,
      uploaded: 0,
    };
  },
  computed: {
    //
  },
  methods: {
    clearAll() {
      this.loading = false;
      this.slug = "";

      this.jobTitleEn = "";
      this.jobTitleEs = "";
      this.jobDescriptionEn = "";
      this.jobDescriptionEs = "";
      this.jobLocationEn = "";
      this.jobLocationEs = "";

      this.loopIndex = 0;
      this.uploading = false;
      this.uploaded = 0;
    },
    addJob() {
      let db = firebase.firestore();
      db.collection("jobs")
        .doc(this.slug)
        .set({
          titleEn: this.jobTitleEn,
          titleEs: this.jobTitleEs,
          locationEn: this.jobLocationEn,
          locationEs: this.jobLocationEs,
          slug: this.slug,
          descriptionEn: this.jobDescriptionEn,
          descriptionEs: this.jobDescriptionEs,
          timestamp: new Date().getTime(),
          applicants: [],
        });
      this.loading = false;
      this.uploading = false;
      setTimeout(() => {
        this.clearAll();
      }, 2000);
    },

    makeSlug() {
      this.slug =
        slugify(this.jobTitleEn, {
          replacement: "-",
          lower: true,
        }) + `-${new Date().getTime()}`;
    },
  },
  mounted() {
    //
  },
};
</script>

<style></style>
