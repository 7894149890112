var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-12",attrs:{"grid-list-xs":""}},[_c('h1',[_vm._v("Messages")]),_c('v-divider',{staticClass:"my-6"}),_c('v-layout',{staticClass:"ma-0",attrs:{"row":""}},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.messages,"items-per-page":20,"loading":_vm.loading,"loading-text":"Loading... Please wait","single-expand":true,"expanded":_vm.expanded,"item-key":"timestamp","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(new Date(item.timestamp))+" ")])]}},{key:"item.reply",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","rounded":"","href":("mailto:" + (item.email))}},[_c('v-icon',[_vm._v("mdi-reply")]),_vm._v(" Reply via Email ")],1)]}},{key:"item.copy",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","rounded":""},on:{"click":function($event){return _vm.copyEmail(item.email)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[_vm._v(_vm._s(item.message))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }