<template>
  <nav>
    <v-app-bar app color="#688D86" dark clipped-left>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Diamond Aesthetics Academy</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn text @click="logout">Logout</v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app clipped>
      <v-layout row align-center justify-center class="ma-0 pa-12">
        <v-avatar size="150" tile>
          <img
            style="object-fit:contain;"
            :src="require('../assets/Diamond-Aesthetics-Academy-Logo.png')"
            alt="Diamond Aesthetics Academy Logo"
          />
        </v-avatar>
      </v-layout>

      <v-list>
        <v-list-item v-for="item in items" :key="item.title" :to="item.route">
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export default {
  name: "App",
  data: () => ({
    drawer: true,
    items: [
      { title: "Courses", route: "/" },
      { title: "Jobs", route: "/jobs" },
      { title: "Users", route: "/users" },
      { title: "Testimonials", route: "/testimonials" },
      { title: "Messages", route: "/messages" },
      { title: "Subscribers", route: "/subscribers" },
      // { title: "Earnings", route: "/earnings" },
    ],
  }),
  methods: {
    logout() {
      firebase.auth().signOut();
      // this.$router.replace({ name: "Login" });
      location.reload();
    },
  },
};
</script>
