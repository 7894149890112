<template>
  <v-container grid-list-xs class="pa-12">
    <v-layout row class="ma-0" align-center justify-start>
      <v-btn icon @click="$router.go(-1)" class="mr-4">
        <v-icon color="black">mdi-arrow-left</v-icon>
      </v-btn>
      <h1>Add New Course</h1>
    </v-layout>
    <input
      ref="mainThumbnail"
      type="file"
      @input="mainThumbnail"
      style="display: none;"
    />
    <input
      ref="fileInput"
      type="file"
      @input="pickFile"
      multiple
      style="display: none;"
    />
    <v-card class="pa-4 my-6">
      <v-layout row wrap class="ma-0">
        <v-flex xs12 md6 v-if="courseThumbnailPreview.length == 0">
          <v-card color="grey lighten-2" flat @click="selectMainThumbnail">
            <v-responsive :aspect-ratio="2 / 1">
              <v-layout column fill-height align-center justify-center>
                <v-layout row align-center justify-center>
                  + Add Main Thumbnail (file size: maximum 3 MB)
                </v-layout>
              </v-layout>
            </v-responsive>
          </v-card>
        </v-flex>
        <v-flex xs12 md6 v-if="courseThumbnailPreview.length == 1">
          <v-img
            :aspect-ratio="2 / 1"
            :src="courseThumbnailPreview[0].url"
            :title="courseThumbnailPreview[0].url"
          >
            <v-layout
              column
              class="ma-0 pa-2"
              fill-height
              align-start
              justify-space-between
            >
              <v-layout row>
                <v-btn
                  dark
                  x-small
                  fab
                  color="red"
                  @click="removeThumbnailImage(0)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-layout>
            </v-layout>
          </v-img>
        </v-flex>
        <v-flex xs12 md6>
          <v-layout row wrap class="ma-0">
            <v-flex xs12 class="px-2">
              <v-text-field
                name="courseTitle"
                label="Course Title English"
                id="course-title"
                :messages="courseSlug"
                v-model="courseTitle"
                @keyup="makeTitleSlug"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-text-field
                name="courseTitle"
                label="Course Title Spanish"
                id="course-title"
                v-model="courseTitleEs"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-select
                :items="['special', 'advanced', 'online', 'main']"
                v-model="courseCategory"
                label="Course Category"
                chips
              ></v-select>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-text-field
                label="Course Hours"
                v-model="courseHours"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-text-field
                label="Course Students"
                v-model="courseStudents"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-text-field
                label="Course Price"
                v-model="coursePrice"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-divider class="my-6"></v-divider>
      <v-layout row wrap class="ma-0">
        <v-flex xs12 md6 class="px-2">
          <v-textarea
            label="Course Description English"
            v-model="courseDescription"
          ></v-textarea>
        </v-flex>
        <v-flex xs12 md6 class="px-2">
          <v-textarea
            label="Course Description Spanish"
            v-model="courseDescriptionEs"
          ></v-textarea>
        </v-flex>
      </v-layout>
      <v-divider class="my-6"></v-divider>
      <v-layout row wrap class="ma-0">
        <v-flex xs12 md6 class="px-2">
          <h3 class="mb-2">Course Details English</h3>
          <wysiwyg v-model="courseDetails" />
        </v-flex>
        <v-flex xs12 md6 class="px-2">
          <h3 class="mb-2">Course Details Spanish</h3>
          <wysiwyg v-model="courseDetailsEs" />
        </v-flex>
      </v-layout>
      <v-divider class="my-6"></v-divider>
      <v-progress-linear
        :indeterminate="true"
        v-if="loading"
      ></v-progress-linear>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark v-if="!uploading" @click="uploadCourse">
          Upload Course
        </v-btn>
        <v-btn dark v-else>
          {{ `Uploading ${uploaded}` }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import slugify from "slugify";
import Axios from "axios";

export default {
  data() {
    return {
      loading: false,
      courseTitle: "",
      courseSlug: "",
      courseCategory: "",
      courseDescription: "",
      courseTitleEs: "",
      courseDescriptionEs: "",
      courseDetailsEs: "",
      courseDetails: "",
      courseHours: "",
      coursePrice: "",
      courseStudents: "",
      courseThumbnail: [],
      courseThumbnailURL: [],
      courseThumbnailPreview: [],
      loopIndex: 0,
      uploading: false,
      uploaded: 0,
    };
  },
  computed: {
    //
  },
  methods: {
    uploadCourse() {
      this.uploadThumbnail();
    },
    clearAll() {
      this.loading = false;
      this.courseTitle = "";
      this.courseSlug = "";
      this.courseCategory = "";
      this.courseDescription = "";
      this.courseTitleEs = "";
      this.courseDescriptionEs = "";
      this.courseDetailsEs = "";
      this.courseDetails = "";
      this.courseHours = "";
      this.coursePrice = "";
      this.courseStudents = "";
      this.courseThumbnail = [];
      this.courseThumbnailURL = [];
      this.courseThumbnailPreview = [];
      this.loopIndex = 0;
      this.uploading = false;
      this.uploaded = 0;
    },
    addCourseToFirestore() {
      let db = firebase.firestore();
      db.collection("courses")
        .doc(this.courseSlug)
        .set({
          title: this.courseTitle,
          titleEs: this.courseTitleEs,
          slug: this.courseSlug,
          category: this.courseCategory,
          description: this.courseDescription,
          descriptionEs: this.courseDescriptionEs,
          details: this.courseDetails,
          detailsEs: this.courseDetailsEs,
          image: this.courseThumbnailURL[0],
          hours: this.courseHours,
          price: this.coursePrice,
          students: this.courseStudents,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
      this.loading = false;
      this.uploading = false;
      setTimeout(() => {
        this.clearAll();
      }, 2000);
    },
    uploadThumbnail() {
      this.loading = true;
      this.uploading = true;
      setTimeout(() => {
        let publicID =
          `DiamondAestheticsAcademy/Courses/` +
          `${this.courseCategory}/` +
          `${this.courseTitle}/` +
          `${this.courseSlug}-thumbnail`;

        let formData = new FormData();
        const file = this.courseThumbnail[0];
        formData.append("file", file);
        formData.append("upload_preset", "kamrankhalid");
        formData.append("public_id", publicID);
        Axios.post(
          `https://api.cloudinary.com/v1_1/kamrankhalid-com/upload`,
          formData
        )
          .then((res) => {
            console.log(res);
            this.courseThumbnailURL.push(res.data.secure_url);
          })
          .catch((err) => console.log({ err }));

        if (this.courseThumbnailURL.length < this.courseThumbnail.length) {
          this.uploadThumbnail();
        } else {
          this.addCourseToFirestore();
        }
      }, 2000);
    },

    removeThumbnailImage(i) {
      const dt = new DataTransfer();
      if (i > -1) {
        for (let index = 0; index < this.courseThumbnail.length; index++) {
          const file = this.courseThumbnail[index];
          if (i !== index) dt.items.add(file); // here you exclude the file. thus removing it.
        }
        this.courseThumbnail = dt.files;
        console.log(this.courseThumbnail);
        this.courseThumbnailURL.splice(i, 1);
        this.courseThumbnailPreview.splice(i, 1);
        // this.courseThumbnail.splice(i, 1);
        // var newFileList;
      }
    },

    selectMainThumbnail() {
      this.$refs.mainThumbnail.click();
    },
    selectImages() {
      this.$refs.fileInput.click();
    },
    mainThumbnail() {
      let input = this.$refs.mainThumbnail;
      let files = input.files;
      console.log(files);
      this.courseThumbnail = files;
      for (let index = 0; index < files.length; index++) {
        let file = files[index];
        this.courseThumbnailPreview.push({
          url: URL.createObjectURL(file),
          uploading: null,
        });
        console.log(this.courseThumbnailPreview);
      }
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let files = input.files;
      this.courseGallery = files;
      for (let index = 0; index < files.length; index++) {
        let file = files[index];
        this.coursePreviewPhotos.push({
          url: URL.createObjectURL(file),
          uploading: null,
        });
      }
    },
    makeTitleSlug() {
      this.courseSlug =
        slugify(this.courseTitle, {
          replacement: "-",
          lower: true,
        }) + `-${new Date().getTime()}`;
    },
    getCategories() {
      this.loading = true;
      let db = firebase.firestore();
      db.collection("categories")
        .where("active", "==", true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.categories.push({ id: doc.id, ...doc.data() });
          });
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>

<style></style>
