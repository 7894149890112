<template>
  <v-container grid-list-xs class="pa-12">
    <h1>Users</h1>
    <v-divider class="my-6"></v-divider>
    <v-layout row class="ma-0">
      <v-card width="100%">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="users"
          :items-per-page="20"
          :loading="loading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.timestamp`]="{ item }">
            <span v-if="item.timestamp">
              {{ new Date(item.timestamp) }}
            </span>
            <span v-else>
              Joined before March 30th
            </span>
          </template>
          <template v-slot:[`item.cart`]="{ item }">
            <v-btn
              outlined
              rounded
              @click="toggleDialog('cart', item.cart)"
              v-if="item.cart.length > 0"
            >
              {{ item.cart.length }}
            </v-btn>
          </template>
          <template v-slot:[`item.courses`]="{ item }">
            <v-btn
              outlined
              rounded
              @click="toggleDialog('courses', item.courses)"
              v-if="item.courses.length > 0"
            >
              {{ item.courses.length }}
            </v-btn>
          </template>
          <template v-slot:[`item.disabled`]="{ item }">
            <v-btn
              icon
              :color="item.disabled ? 'red' : 'green'"
              @click="disableUser(item)"
            >
              <v-icon v-if="item.disabled == true">
                mdi-account-cancel-outline
              </v-icon>
              <v-icon v-else>mdi-account-check-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
    <v-dialog v-model="cartPreviewDialog" max-width="720px">
      <v-card>
        <v-card-title primary-title>
          Courses in cart
        </v-card-title>
        <v-card-text>
          <v-chip class="ma-1" v-for="(item, i) in cartPreview" :key="item + i">
            {{ item }}
          </v-chip>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="coursesPreviewDialog" max-width="720px">
      <v-card>
        <v-card-title primary-title>
          Courses purchased
        </v-card-title>
        <v-card-text>
          <v-chip
            class="ma-1"
            v-for="(item, i) in coursesPreview"
            :key="item + i"
          >
            {{ item }}
          </v-chip>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export default {
  data() {
    return {
      loading: false,
      cartPreview: [],
      cartPreviewDialog: false,
      coursesPreview: [],
      coursesPreviewDialog: false,
      search: "",
      headers: [
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "Phone Number",
          align: "start",
          sortable: false,
          value: "phoneNumber",
        },
        { text: "Cart", value: "cart" },
        { text: "Courses", value: "courses" },
        { text: "Timestamp", value: "timestamp" },
        { text: "UID", value: "uid" },
        { text: "Disabled", value: "disabled" },
      ],
      users: [],
      imagePreview: false,
      previewImages: [],
    };
  },
  methods: {
    async disableUser(user) {
      console.log("disableUser", user);
      let db = firebase.firestore();
      if (user.disabled == true) {
        await db
          .collection("users")
          .doc(user.uid)
          .update({
            disabled: false,
          });
        this.getUsers();
      } else {
        await db
          .collection("users")
          .doc(user.uid)
          .update({
            disabled: true,
          });
        this.getUsers();
      }
    },
    openImagePreview(photos) {
      this.imagePreview = true;
      this.previewImages = photos;
    },
    toggleDialog(dialog, items) {
      if (dialog == "cart") {
        this.cartPreview = items;
        this.cartPreviewDialog = true;
      }
      if (dialog == "courses") {
        this.coursesPreview = items;
        this.coursesPreviewDialog = true;
      }
    },
    getUsers() {
      this.users = [];
      this.loading = true;
      let db = firebase.firestore();
      db.collection("users")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.users.push({ id: doc.id, ...doc.data() });
          });
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<style></style>
