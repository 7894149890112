<template>
  <v-container grid-list-xs class="pa-12">
    <h1>Messages</h1>
    <v-divider class="my-6"></v-divider>
    <v-layout row class="ma-0">
      <v-card width="100%">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="messages"
          :items-per-page="20"
          :loading="loading"
          loading-text="Loading... Please wait"
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="timestamp"
          show-expand
        >
          <!-- <template v-slot:[`item.firstName`]="{ item }">
            <span>{{ item.firstName }} {{ item.lastName }}</span>
          </template> -->
          <template v-slot:[`item.timestamp`]="{ item }">
            <span>
              {{ new Date(item.timestamp) }}
            </span>
          </template>
          <template v-slot:[`item.reply`]="{ item }">
            <v-btn text rounded :href="`mailto:${item.email}`">
              <v-icon>mdi-reply</v-icon>
              Reply via Email
            </v-btn>
          </template>
          <template v-slot:[`item.copy`]="{ item }">
            <v-btn icon rounded @click="copyEmail(item.email)">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <v-btn icon @click="deleteItem(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td class="pa-4" :colspan="headers.length">{{ item.message }}</td>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export default {
  data() {
    return {
      loading: false,
      search: "",
      expanded: [],
      headers: [
        { text: "Name", sortable: false, value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "contactNumber" },
        // { text: "Message", value: "message", width: '300px' },
        { text: "Timestamp", value: "timestamp" },
        { text: "", sortable: false, value: "reply" },
        { text: "", sortable: false, value: "copy" },
        { text: "", sortable: false, value: "id" },
        { text: "", value: "data-table-expand" },
      ],
      messages: [],
    };
  },
  methods: {
    copyEmail(email) {
      navigator.clipboard.writeText(email);
    },
    getMessages() {
      this.messages = [];
      this.loading = true;
      let db = firebase.firestore();
      db.collection("messages")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // console.log({ ...doc.data() });
            this.messages.push({ id: doc.id, ...doc.data() });
          });
          this.loading = false;
        });
    },
    deleteItem(id) {
      this.loading = true;
      let db = firebase.firestore();
      db.collection("messages")
        .doc(id)
        .delete()
        .then(() => {
          this.loading = false;
          this.getMessages();
        });
    },
  },
  mounted() {
    this.getMessages();
  },
};
</script>

<style></style>
