<template>
  <v-container grid-list-xs class="pa-12">
    <h1>Jobs</h1>
    <v-layout row class="ma-0" align-center justify-end>
      <v-btn @click="$router.push({ name: 'AddNewJob' })" dark>
        <v-icon>mdi-plus</v-icon>
        Add New Job
      </v-btn>
    </v-layout>
    <v-divider class="my-6"></v-divider>
    <v-layout row class="ma-0">
      <v-card width="100%">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="jobs"
          :items-per-page="20"
          :loading="loading"
          loading-text="Loading... Please wait"
        >
          <!-- <template v-slot:[`item.descriptionEn`]="{ item }">
            <p v-html="item.descriptionEn"></p>
          </template> -->
          <template v-slot:[`item.timestamp`]="{ item }">
            {{ new Date(item.timestamp) }}
          </template>
          <template v-slot:[`item.applicants`]="{ item }">
            <v-btn
              outlined
              rounded
              @click="toggleDialog('applicants', item.applicants)"
              v-if="item.applicants.length > 0"
            >
              {{ item.applicants.length }}
            </v-btn>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <v-btn icon @click="deleteProject(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-btn icon :to="`edit-job?job=${item.id}`">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
    <v-dialog v-model="applicantsDialog" max-width="720px">
      <v-card>
        <v-card-title primary-title>
          Applicants
        </v-card-title>
        <v-card-text>
          <!-- {{ applicants }} -->
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Email
                  </th>
                  <th class="text-left">
                    Contact Number
                  </th>
                  <th class="text-left">
                    Resume
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in applicants" :key="i.email">
                  <td>{{ i.name }}</td>
                  <td>{{ i.email }}</td>
                  <td>{{ i.contactNumber }}</td>
                  <td>
                    <a
                      :href="i.resume"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Resume
                    </a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export default {
  data() {
    return {
      loading: false,
      applicants: [],
      applicantsDialog: false,
      search: "",
      headers: [
        {
          text: "Job Title",
          align: "start",
          sortable: false,
          value: "titleEn",
        },
        { text: "Location", value: "locationEn" },
        { text: "Applicants", value: "applicants" },
        { text: "Date", value: "timestamp" },
        // { text: "Description", value: "descriptionEn" },
        { text: "", sortable: false, value: "id" },
        { text: "", sortable: false, value: "edit" },
      ],
      jobs: [],
      imagePreview: false,
      previewImages: [],
    };
  },
  methods: {
    openImagePreview(photos) {
      this.imagePreview = true;
      this.previewImages = photos;
    },
    toggleDialog(dialog, items) {
      this.applicants = [];
      if (dialog == "applicants") {
        this.applicants = items;
        this.applicantsDialog = true;
      }
    },
    // resizedPhoto(photo) {
    //   return photo.replace(
    //     "https://res.cloudinary.com/advargo-sports/image/upload/",
    //     "https://res.cloudinary.com/advargo-sports/image/upload/w_50,h_50,c_fill/"
    //   );
    // },
    getData() {
      this.jobs = [];
      this.loading = true;
      let db = firebase.firestore();
      db.collection("jobs")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.jobs.push({ id: doc.id, ...doc.data() });
            console.log({ id: doc.id, ...doc.data() });
          });
          this.loading = false;
        });
    },
    deleteProject(id) {
      this.loading = true;
      let db = firebase.firestore();
      db.collection("jobs")
        .doc(id)
        .delete()
        .then(() => {
          this.loading = false;
          this.getData();
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
