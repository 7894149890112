<template>
  <v-container grid-list-xs class="pa-12">
    <h1>Courses</h1>
    <v-layout row class="ma-0" align-center justify-end>
      <v-btn @click="$router.push({ name: 'AddNewCourse' })" dark>
        <v-icon>mdi-plus</v-icon>
        Add New Course
      </v-btn>
    </v-layout>
    <v-divider class="my-6"></v-divider>
    <v-layout row class="ma-0">
      <v-card width="100%">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="projects"
          :items-per-page="20"
          :loading="loading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-img height="48" width="64" :src="item.image"></v-img>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <v-btn icon @click="deleteProject(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-btn icon :to="`edit-course?course=${item.id}`">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export default {
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        { text: "Image", value: "image" },
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
        },
        { text: "Hours", value: "hours" },
        { text: "Student", value: "students" },
        { text: "Price", value: "price" },
        { text: "Description", value: "description" },
        { text: "", sortable: false, value: "id" },
        { text: "", sortable: false, value: "edit" },
      ],
      projects: [],
      imagePreview: false,
      previewImages: [],
    };
  },
  methods: {
    openImagePreview(photos) {
      this.imagePreview = true;
      this.previewImages = photos;
    },
    // resizedPhoto(photo) {
    //   return photo.replace(
    //     "https://res.cloudinary.com/advargo-sports/image/upload/",
    //     "https://res.cloudinary.com/advargo-sports/image/upload/w_50,h_50,c_fill/"
    //   );
    // },
    getProjects() {
      this.projects = [];
      this.loading = true;
      let db = firebase.firestore();
      db.collection("courses")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.projects.push({ id: doc.id, ...doc.data() });
          });
          this.loading = false;
        });
    },
    deleteProject(id) {
      this.loading = true;
      let db = firebase.firestore();
      db.collection("courses")
        .doc(id)
        .delete()
        .then(() => {
          this.loading = false;
          this.getProjects();
        });
    },
  },
  mounted() {
    this.getProjects();
  },
};
</script>

<style></style>
