var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-12",attrs:{"grid-list-xs":""}},[_c('h1',[_vm._v("Jobs")]),_c('v-layout',{staticClass:"ma-0",attrs:{"row":"","align-center":"","justify-end":""}},[_c('v-btn',{attrs:{"dark":""},on:{"click":function($event){return _vm.$router.push({ name: 'AddNewJob' })}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add New Job ")],1)],1),_c('v-divider',{staticClass:"my-6"}),_c('v-layout',{staticClass:"ma-0",attrs:{"row":""}},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.jobs,"items-per-page":20,"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.timestamp))+" ")]}},{key:"item.applicants",fn:function(ref){
var item = ref.item;
return [(item.applicants.length > 0)?_c('v-btn',{attrs:{"outlined":"","rounded":""},on:{"click":function($event){return _vm.toggleDialog('applicants', item.applicants)}}},[_vm._v(" "+_vm._s(item.applicants.length)+" ")]):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteProject(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":("edit-job?job=" + (item.id))}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"720px"},model:{value:(_vm.applicantsDialog),callback:function ($$v) {_vm.applicantsDialog=$$v},expression:"applicantsDialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Applicants ")]),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Name ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Email ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Contact Number ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Resume ")])])]),_c('tbody',_vm._l((_vm.applicants),function(i){return _c('tr',{key:i.email},[_c('td',[_vm._v(_vm._s(i.name))]),_c('td',[_vm._v(_vm._s(i.email))]),_c('td',[_vm._v(_vm._s(i.contactNumber))]),_c('td',[_c('a',{attrs:{"href":i.resume,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" View Resume ")])])])}),0)]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }