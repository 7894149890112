import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/compat/app";
import Cloudinary from "cloudinary-vue";
import "vue-wysiwyg/dist/vueWysiwyg.css";
import wysiwyg from "vue-wysiwyg";

Vue.use(wysiwyg, {
  hideModules: {
    image: true,
    table: true,
    headings: true,
    link: true,
    code: true,
  },
});

Vue.config.productionTip = false;

Vue.use(Cloudinary, {
  configuration: {
    cloudName: "inoverus",
  },
});

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBHQE4PVo6rhXyL-u4Vp5r0UlPJ8VH_77k",
  authDomain: "diamond-aesthetics-academy.firebaseapp.com",
  projectId: "diamond-aesthetics-academy",
  storageBucket: "diamond-aesthetics-academy.appspot.com",
  messagingSenderId: "312132373212",
  appId: "1:312132373212:web:3a6ac1c35b17d1ccd80219",
  measurementId: "G-Z0QYF03N41"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

let app;

firebase.auth().onAuthStateChanged((user) => {
  console.log("user", user);
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }
});

// new Vue({
//   router,
//   store,
//   vuetify,
//   render: h => h(App)
// }).$mount('#app')
