<template>
  <v-container grid-list-xs class="pa-12">
    <v-layout row class="ma-0" align-center justify-start>
      <v-btn icon @click="$router.go(-1)" class="mr-4">
        <v-icon color="black">mdi-arrow-left</v-icon>
      </v-btn>
      <h1>Add New Testimonial</h1>
    </v-layout>
    <input
      ref="mainThumbnail"
      type="file"
      @input="mainThumbnail"
      style="display: none;"
    />
    <input
      ref="fileInput"
      type="file"
      @input="pickFile"
      multiple
      style="display: none;"
    />
    <v-card class="pa-4 my-6">
      <v-layout row wrap class="ma-0">
        <v-flex xs12 md3 v-if="imageThumbnailPreview.length == 0">
          <v-card color="grey lighten-2" flat @click="selectMainThumbnail">
            <v-responsive :aspect-ratio="1 / 1">
              <v-layout column fill-height align-center justify-center>
                <v-layout
                  row
                  align-center
                  justify-center
                  class="ma-0 pa-2 text-center"
                >
                  + Add Image <br />(file size: maximum 1 MB)
                </v-layout>
              </v-layout>
            </v-responsive>
          </v-card>
        </v-flex>
        <v-flex xs12 md3 v-if="imageThumbnailPreview.length == 1">
          <v-img
            :aspect-ratio="1 / 1"
            :src="imageThumbnailPreview[0].url"
            :title="imageThumbnailPreview[0].url"
          >
            <v-layout
              column
              class="ma-0 pa-2"
              fill-height
              align-start
              justify-space-between
            >
              <v-layout row>
                <v-btn
                  dark
                  x-small
                  fab
                  color="red"
                  @click="removeThumbnailImage(0)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-layout>
            </v-layout>
          </v-img>
        </v-flex>
        <v-flex xs12 md9>
          <v-layout row wrap class="ma-0">
            <v-flex xs12 class="px-2">
              <v-text-field
                name="name"
                label="Name"
                id="testimonial-name"
                v-model="name"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-textarea
                label="Review (English)"
                v-model="review_en"
              ></v-textarea>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-textarea
                label="Review (Spanish)"
                v-model="review_es"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-divider class="my-6"></v-divider>
      <v-progress-linear
        :indeterminate="true"
        v-if="loading"
      ></v-progress-linear>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark v-if="!uploading" @click="uploadTestimonial">
          Upload Testimonial
        </v-btn>
        <v-btn dark v-else>
          {{ `Uploading ${uploaded}` }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import Axios from "axios";

export default {
  data() {
    return {
      loading: false,
      name: "",
      review_en: "",
      review_es: "",
      imageThumbnail: [],
      imageThumbnailURL: [],
      imageThumbnailPreview: [],
      loopIndex: 0,
      uploading: false,
      uploaded: 0,
    };
  },
  computed: {
    //
  },
  methods: {
    uploadTestimonial() {
      this.uploadThumbnail();
    },
    clearAll() {
      this.loading = false;
      this.name = "";
      this.review_en = "";
      this.review_es = "";
      this.imageThumbnail = [];
      this.imageThumbnailURL = [];
      this.imageThumbnailPreview = [];
      this.loopIndex = 0;
      this.uploading = false;
      this.uploaded = 0;
    },
    addTestimonialToFirestore() {
      let db = firebase.firestore();
      db.collection("testimonials")
        .add({
          name: this.name,
          review_en: this.review_en,
          review_es: this.review_es,
          image: this.imageThumbnailURL[0],
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
      this.loading = false;
      this.uploading = false;
      setTimeout(() => {
        this.clearAll();
      }, 2000);
    },
    uploadThumbnail() {
      this.loading = true;
      this.uploading = true;
      setTimeout(() => {
        let publicID =
          `DiamondAestheticsAcademy/Testimonials/` +
          `${this.name}/` +
          `${this.name}-thumbnail`;

        let formData = new FormData();
        const file = this.imageThumbnail[0];
        formData.append("file", file);
        formData.append("upload_preset", "kamrankhalid");
        formData.append("public_id", publicID);
        Axios.post(
          `https://api.cloudinary.com/v1_1/kamrankhalid-com/upload`,
          formData
        )
          .then((res) => {
            console.log(res);
            this.imageThumbnailURL.push(res.data.secure_url);
          })
          .catch((err) => console.log({ err }));

        if (this.imageThumbnailURL.length < this.imageThumbnail.length) {
          this.uploadThumbnail();
        } else {
          this.addTestimonialToFirestore();
        }
      }, 2000);
    },

    removeThumbnailImage(i) {
      const dt = new DataTransfer();
      if (i > -1) {
        for (let index = 0; index < this.imageThumbnail.length; index++) {
          const file = this.imageThumbnail[index];
          if (i !== index) dt.items.add(file); // here you exclude the file. thus removing it.
        }
        this.imageThumbnail = dt.files;
        console.log(this.imageThumbnail);
        this.imageThumbnailURL.splice(i, 1);
        this.imageThumbnailPreview.splice(i, 1);
        // this.imageThumbnail.splice(i, 1);
        // var newFileList;
      }
    },

    selectMainThumbnail() {
      this.$refs.mainThumbnail.click();
    },
    selectImages() {
      this.$refs.fileInput.click();
    },
    mainThumbnail() {
      let input = this.$refs.mainThumbnail;
      let files = input.files;
      console.log(files);
      this.imageThumbnail = files;
      for (let index = 0; index < files.length; index++) {
        let file = files[index];
        this.imageThumbnailPreview.push({
          url: URL.createObjectURL(file),
          uploading: null,
        });
        console.log(this.imageThumbnailPreview);
      }
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let files = input.files;
      this.courseGallery = files;
      for (let index = 0; index < files.length; index++) {
        let file = files[index];
        this.coursePreviewPhotos.push({
          url: URL.createObjectURL(file),
          uploading: null,
        });
      }
    },
  },
  mounted() {
    // 
  },
};
</script>

<style></style>
