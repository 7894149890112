var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-12",attrs:{"grid-list-xs":""}},[_c('h1',[_vm._v("Users")]),_c('v-divider',{staticClass:"my-6"}),_c('v-layout',{staticClass:"ma-0",attrs:{"row":""}},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.users,"items-per-page":20,"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [(item.timestamp)?_c('span',[_vm._v(" "+_vm._s(new Date(item.timestamp))+" ")]):_c('span',[_vm._v(" Joined before March 30th ")])]}},{key:"item.cart",fn:function(ref){
var item = ref.item;
return [(item.cart.length > 0)?_c('v-btn',{attrs:{"outlined":"","rounded":""},on:{"click":function($event){return _vm.toggleDialog('cart', item.cart)}}},[_vm._v(" "+_vm._s(item.cart.length)+" ")]):_vm._e()]}},{key:"item.courses",fn:function(ref){
var item = ref.item;
return [(item.courses.length > 0)?_c('v-btn',{attrs:{"outlined":"","rounded":""},on:{"click":function($event){return _vm.toggleDialog('courses', item.courses)}}},[_vm._v(" "+_vm._s(item.courses.length)+" ")]):_vm._e()]}},{key:"item.disabled",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":item.disabled ? 'red' : 'green'},on:{"click":function($event){return _vm.disableUser(item)}}},[(item.disabled == true)?_c('v-icon',[_vm._v(" mdi-account-cancel-outline ")]):_c('v-icon',[_vm._v("mdi-account-check-outline")])],1)]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"720px"},model:{value:(_vm.cartPreviewDialog),callback:function ($$v) {_vm.cartPreviewDialog=$$v},expression:"cartPreviewDialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Courses in cart ")]),_c('v-card-text',_vm._l((_vm.cartPreview),function(item,i){return _c('v-chip',{key:item + i,staticClass:"ma-1"},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)],1),_c('v-dialog',{attrs:{"max-width":"720px"},model:{value:(_vm.coursesPreviewDialog),callback:function ($$v) {_vm.coursesPreviewDialog=$$v},expression:"coursesPreviewDialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Courses purchased ")]),_c('v-card-text',_vm._l((_vm.coursesPreview),function(item,i){return _c('v-chip',{key:item + i,staticClass:"ma-1"},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }