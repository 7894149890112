<template>
  <v-app>
    <Navigation
      v-if="loggedIn && loggedInEmail == 'admin@diamondaestheticsacademy.com'"
    />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import Navigation from "./components/Navigation.vue";

export default {
  name: "App",
  components: { Navigation },
  data: () => ({
    //
  }),
  computed: {
    loggedIn() {
      // console.log(firebase.auth().currentUser.email);
      return firebase.auth().currentUser;
    },
    loggedInEmail() {
      if (firebase.auth().currentUser) {
        return firebase.auth().currentUser.email;
      } else return false;
    },
  },
};
</script>
<style>
.v-image__image--cover {
  background-size: contain;
}
</style>
