<template>
  <v-container grid-list-xs class="pa-12">
    <h1>Subscribers</h1>
    <v-layout row class="ma-0" align-center justify-end>
      <v-btn @click="copyEmails" dark>
        <v-icon>mdi-content-copy</v-icon>
        Copy Emails
      </v-btn>
    </v-layout>
    <v-divider class="my-6"></v-divider>
    <v-layout row class="ma-0">
      <v-card width="100%">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="items"
          :items-per-page="20"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="item.id"
        >
          <template v-slot:[`item.email`]="{ item }">
            {{ item.email }}
          </template>
          <template v-slot:[`item.timestamp`]="{ item }">
            <span>
              {{ new Date(item.timestamp) }}
            </span>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <v-btn icon @click="deleteItem(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
// import { arrayRemove } from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export default {
  data() {
    return {
      loading: false,
      search: "",
      expanded: [],
      headers: [
        { text: "Email", value: "email" },
        { text: "Timestamp", value: "timestamp" },
        { text: "", sortable: false, value: "id" },
      ],
      items: [],
    };
  },
  methods: {
    copyEmails() {
      navigator.clipboard.writeText(this.items.map((e) => e.email));
    },
    getItems() {
      this.items = [];
      this.loading = true;
      let db = firebase.firestore();
      db.collection("subscribers")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.items.push({ id: doc.id, ...doc.data() });
          });
          this.loading = false;
        });
    },
    deleteItem(id) {
      this.loading = true;
      let db = firebase.firestore();
      db.collection("subscribers")
        .doc(id)
        .delete()
        .then(() => {
          this.loading = false;
          this.getItems();
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style></style>
