import Vue from "vue";
import VueRouter from "vue-router";
// import Dashboard from "../views/Dashboard.vue";
import Users from "../views/Users.vue";
import Courses from "../views/Courses.vue";
import Jobs from "../views/Jobs.vue";
import Earnings from "../views/Earnings.vue";
import Messages from "../views/Messages.vue";
import Testimonials from "../views/Testimonials.vue";
import Subscribers from "../views/Subscribers.vue";
import AddNewCourse from "../views/AddNewCourse.vue";
import AddNewJob from "../views/AddNewJob.vue";
import AddNewTestimonial from "../views/AddNewTestimonial.vue";
import EditCourse from "../views/EditCourse.vue";
import EditJob from "../views/EditJob.vue";
import Login from "../views/Login.vue";

// import AddNewProject from "../views/AddNewProject.vue";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Courses",
    component: Courses,
    meta: { requiresAuth: true },
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
    meta: { requiresAuth: true },
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    component: Testimonials,
    meta: { requiresAuth: true },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: { requiresAuth: true },
  },
  {
    path: "/earnings",
    name: "Earnings",
    component: Earnings,
    meta: { requiresAuth: true },
  },
  {
    path: "/messages",
    name: "Messages",
    component: Messages,
    meta: { requiresAuth: true },
  },
  {
    path: "/subscribers",
    name: "Subscribers",
    component: Subscribers,
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/add-new-project",
  //   name: "AddNewProject",
  //   component: AddNewProject,
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/add-new-course",
    name: "AddNewCourse",
    component: AddNewCourse,
    meta: { requiresAuth: true },
  },
  {
    path: "/add-new-job",
    name: "AddNewJob",
    component: AddNewJob,
    meta: { requiresAuth: true },
  },
  {
    path: "/edit-course",
    name: "EditCourse",
    component: EditCourse,
    meta: { requiresAuth: true },
  },
  {
    path: "/edit-job",
    name: "EditJob",
    component: EditJob,
    meta: { requiresAuth: true },
  },
  {
    path: "/add-new-testimonials",
    name: "AddNewTestimonial",
    component: AddNewTestimonial,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = firebase.auth().currentUser;
  console.log("isauthenticated", isAuthenticated);
  console.log("requiresAuth", requiresAuth);
  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else {
    next();
  }
});

export default router;
